<template>
  <title>SIM INVENTORY ~ ADD RETUR ITEM TO WH DATA</title>
  <!-- Content Header (Page header) -->
  <section class="content-header">
    <h1>
      Add Retur Item To WH Data
      <br />
      <h4>Please Enter Retur Item To WH Data Data</h4>
      <!-- <small>Control panel</small> -->
    </h1>
    <ol class="breadcrumb">
      <li>
        <a href="#"><i class="fa fa-dashboard"></i> Main Navigation</a>
      </li>
      <li class="active">Retur Item To WH Data</li>
    </ol>
  </section>

  <section class="content">
    <div v-if="loading" class="load">
      <img width="130" src="@/assets/loader.gif" alt="" />
    </div>
    <div class="row">
      <!--<div class="col-md-4">
        <div class="box box-primary">
          <form role="form">
            <div class="box-body">
              <div class="form-group">
                <label for="exampleInputEmail1">Date</label>
                <input
                  type="date"
                  v-model="tglret"
                  autocomplete="off"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Retur Number</label>
                <input
                  type="text"
                  v-model="nomorkewh"
                  autocomplete="off"
                  class="form-control"
                  placeholder="Retur Number"
                />
              </div>
            </div>
          </form>
        </div>
      </div> -->
      <div class="col-md-12">
        <div class="box box-primary">
          <div class="box-header">
            <div class="box-body">
              <div class="row">
                <div class="col-lg-6">
                  <label>Date</label>
                  <div class="input-group">
                    <span class="input-group-addon"
                      ><i class="fa fa-calendar"></i
                    ></span>
                    <input
                      type="date"
                      v-model="tglret"
                      class="form-control"
                      placeholder="Date"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <label>Retur Number</label>
                  <div class="input-group">
                    <span class="input-group-addon"
                      ><i class="fa fa-refresh"></i
                    ></span>
                    <input
                      type="text"
                      v-model="nomorkewh"
                      class="form-control"
                      placeholder="Retur Number"
                    />
                  </div>
                </div>

                <!--<div class="col-md-2">
                  Date &nbsp; :
                </div>
                <div class="col-md-3">
                  <input
                    type="date"
                    v-model="tglret"
                    class="form-control"
                    name=""
                    id=""
                  />
                </div>
                <div class="col-md-2">
                  Retur Number &nbsp; :
                </div>
                <div class="col-md-3">
                  <input
                    type="text"
                    v-model="nomorkewh"
                    class="form-control"
                    name=""
                    id=""
                    placeholder="Retur Number"
                  />
                </div> -->
              </div>
            </div>
            <p></p>
          </div>

          <div class="box-header">
            <div class="box-header with-border">
              <i class="fa fa-list"></i>
              <h3 class="box-title">Stok Production Material</h3>
            </div>
            <div class="box-body">
              <div class="row">
                <div class="col-md-5">
                  Show &nbsp; :
                  <select
                    v-model="pageSize"
                    @change="handlePageSizeChange($event)"
                  >
                    <option v-for="size in pageSizes" :key="size" :value="size">
                      {{ size }}
                    </option>
                  </select>
                  results
                </div>
                <div class="col-md-5">
                  <input
                    type="text"
                    autocomplete="off"
                    placeholder="Search by outgoing number"
                    class="form-control"
                    v-model="namesearch"
                  />
                </div>
                <div class="col-md-2">
                  <button
                    @click="searchdata()"
                    type="button"
                    class="btn btn-info"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
              <!-- /.box-body -->
            </div>
          </div>

          <!-- /.box-header -->
          <div v-if="isExist2" class="box-body">
            <div class="table-responsive">
              <table id="mydata" class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Outgoing Number</th>
                    <th>Out Date</th>
                    <th>Order Number</th>
                    <th>Buyer Name</th>
                    <th>Product Name</th>
                    <th>Item Code</th>
                    <th>Qty</th>
                    <th>Notes</th>
                    <th>Stock</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(classdata, index) in listdata"
                    :key="classdata.id"
                  >
                    <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                    <td>{{ classdata.outgoing_number }}</td>
                    <td>{{ classdata.date_out }}</td>
                    <td>{{ classdata.order_number }}</td>
                    <td>{{ classdata.nama_buyer }}</td>
                    <td>{{ classdata.nama_produk }}</td>
                    <td>{{ classdata.kode_barang }}</td>
                    <td>
                      <input
                        :id="'inptp-' + classdata.id"
                        type="text"
                        class="form-control"
                        v-model="classdata.qtyjml"
                        :name="'nmtp-' + classdata.id"
                      />
                    </td>
                    <td>
                      <input
                        :id="'inptn-' + classdata.id"
                        type="text"
                        class="form-control"
                        v-model="classdata.catatan"
                        :name="'nmtn-' + classdata.id"
                      />
                    </td>
                    <td>{{ classdata.stok }}</td>
                    <td>
                      <button
                        @click="saveData(classdata)"
                        type="button"
                        class="btn btn-primary"
                      >
                        <i class="fa fa-save"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <v-pagination
                v-model="page"
                :pages="count"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="handlePageChange"
              />
            </div>
          </div>
          <div v-else class="box-body">
            <div class="table-responsive">
              <table id="mydata" class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Outgoing Number</th>
                    <th>Out Date</th>
                    <th>Order Number</th>
                    <th>Buyer Name</th>
                    <th>Product Name</th>
                    <th>Item Code</th>
                    <th>Stock</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="7">
                      <center>
                        <b style="color: red"> ... Data Not Found ... </b>
                      </center>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- /.box-body -->
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import swal from "sweetalert";
import axios from "axios";

export default {
  name: "tracebility",
  components: {
    VPagination
  },
  data() {
    return {
      loading: false,
      isSearch: false,
      isSearch2: true,
      isExist: true,
      isExist2: true,
      namesearch: "",
      list_paged: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      nomorkewh: "",
      tglret: "",
      pageSizes: [10, 50, 100, 500],
      classdata: {
        id: "",
        catatan: "",
        qtyjml: ""
      }
    };
  },
  created() {
    this.getnow();
    this.fetchDataOrderBuyer();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      if (this.namesearch == "") {
        this.fetchDataOrderBuyer();
      } else {
        this.searchdata();
      }
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      if (this.namesearch == "") {
        this.fetchDataOrderBuyer();
      } else {
        this.searchdata();
      }
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    getnow() {
      const today = new Date();
      const tahun = today.getFullYear();
      var month = today.getMonth();
      if (String(month).length == 1) {
        month = "0" + (month + 1);
      } else {
        month;
      }
      var tgl = today.getDate();
      if (String(tgl).length == 1) {
        tgl = "0" + tgl;
      } else {
        tgl;
      }
      // const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      this.tglret = tahun + "-" + month + "-" + tgl;
    },
    searchdata() {
      this.loading = true;
      const paramsst = this.getRequestParams(this.page);
      //const paramsst = this.getRequestParams(this.pagestat);
      const tokenlogin = sessionStorage.getItem("token");
      // const urlApiGETdetail = "http://26.183.23.191/inventory/backend/inventory/api/buyer_order/getalldatabuyer_order?length="+this.pageSize+"&cari="+this.namesearch+"&page="+this.page
      const urlApiGETdetail =
        this.$apiurl +
        "retur_barang_ke_wh/getalldatastok_material_di_produksi?length=" +
        this.pageSize +
        "&cari=" +
        this.namesearch +
        "&page=" +
        this.page +
        "&kode_user=" +
        sessionStorage.getItem("kodeuser");
      axios
        .get(urlApiGETdetail, {
          paramsst,
          headers: { Authorization: tokenlogin }
        })
        .then((resp) => {
          if (resp.data.data.length == 0) {
            this.isExist2 = false;
          } else {
            this.isExist2 = true;
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    async fetchDataOrderBuyer() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      // const urlApiGETdetail = "http://26.183.23.191/inventory/backend/inventory/api/buyer_order/getalldatabuyer_order?length="+this.pageSize+"&page="+this.page
      const urlApiGETdetail =
        this.$apiurl +
        "retur_barang_ke_wh/getalldatastok_material_di_produksi?length=" +
        this.pageSize +
        "&page=" +
        this.page +
        "&kode_user=" +
        sessionStorage.getItem("kodeuser");
      axios
        .get(urlApiGETdetail, { headers: headers })
        .then((resp) => {
          console.log(resp);
          if (resp.data.data.length == 0) {
            this.isExist2 = false;
          } else {
            this.isExist2 = true;
            this.listdata = resp.data.data;
            this.count = resp.data.datatotalcount;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          });
        });
    },
    saveData(classdata) {
      this.loading = true;
      var nm = this.nomorkewh;
      var jmlq = classdata.qtyjml;

      if (nm == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Retur Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (jmlq == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (jmlq > classdata.stok) {
        swal({
          icon: "warning",
          title: "Warning",
          text: "qty can't be more than stock",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        var kodeuser = sessionStorage.getItem("kodeuser");
        var paramdata = {
          nomor_ke_warehouse: nm,
          id_outgoing_d: classdata.id,
          notes: classdata.catatan,
          qty: jmlq,
          kode_user: kodeuser,
          tanggal: this.tglret
        };

        console.log(paramdata);
        var tokenlogin = sessionStorage.getItem("token");
        var urlAPIsavedata =
          this.$apiurl + "retur_barang_ke_wh/saveretur_barang_ke_wh";

        var headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPIsavedata, paramdata, { headers })
          .then((resp) => {
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Save data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              window.location.href = "/returitem-towh";
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Save data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
    /* cariOrderNumber(classdataorderbuyer) {
      this.loading = true;
      this.isSearch = true;
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "retur_barang_ke_wh/getalldatastok_material_di_produksi?order_number=" +
        classdataorderbuyer +
        "&kode_user=" +
        sessionStorage.getItem("kodeuser") +
        "&page=" +
        this.page +
        "&length=" +
        this.pageSize;
      console.log(urlAPIget);
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            this.isExist = false;
          } else {
            this.isExist = true;
            this.isSearch2 = false;
            this.listdata = resp.data.data;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    } */
  }
};
</script>
